<template>
  <div>
    <div class="outer-wrapper" :style="{width:main_width+'px'}">
      <div class="m-option-wrapper" v-if="withOption">
        <option-box
          v-for="element in optionData.optionList"
          :selist="optionData.selected_list"
          :main_title="element.option_title"
          :choices="element.value_list"
          :key="element.value_list.id"
          :key_name="element.key_name"
          @click_f="click_fu"
        ></option-box>
        <selected-box :selist="optionData.selected_list" @minus_f="minus_fu2"></selected-box>
        <div class="button-wrapper">
          <div class="keyword-input-wrapper">
            <label class="keyword-label">
              关键字：
              <Input v-model="optionData.keyword" placeholder="请输入..." clearable class="keyword-input" />
            </label>
          </div>
          <Button class="main-button" type="primary" icon="ios-search" @click="go_search">开始筛选</Button>
          <Button class="main-button re-button" type="primary" @click="reset_choices">重置选项</Button>
        </div>
      </div>
      <div class="table-wrapper" id="data-list">
            <list-with-page :tableParams="tableParams" @handlePage="handlePage"></list-with-page>
      </div>
    </div>
  </div>
</template>
<script>
import optionBox from "./OptionBox.vue";
import selectedBox from "./SelectedBox.vue";
import listWithPage from "./ListWithPage.vue";
//import { constants } from 'crypto';
let userToken = localStorage.getItem("current_user_token");

export default {
  name: "queryList",
  components: {
    optionBox,
    selectedBox,
    listWithPage,
  },
  props: {
    "columnsFormatData":{
        type:Array,
        required:true,
    },
    "uri_name":{
        type:String,
        default:'/ask_for_data'
    },
    'withOption':{
      type:Boolean,
      default:true,
    },
    "uri_option_name":{
        type:String,
        default:'/ask_for_option'
    },
    "main_width":{
        type:String,
        default:'1200'
    },
    'askData':{
        type:String,
        required:true
    },
    'refreshData':{
        type:Number,
        default:1
    },
    'selectedRow':{
      type:Array,
      default:function(){
        return [];
      }
    }
    },
  model:{
    prop:'selectedRow',
    event:'on-selection-change'
  },
  data() {
    return {
        tableParams: {
          Tpage: {
            currentPage: 1,
            currentSize: 20
          },
          totalItems: 0,
          noDataText: "",
          loading: true,
          columnsFormat: this.columnsFormatData,
          contentData: [],
          tableWidth: parseInt(this.main_width)-30,
          pageSizeOptions: [20, 50, 100, 200, 400]
        },
        optionData:{
            optionList: [
                    /**{'option_title': "归属城市",'key_name':'city','value_list':[{'text':'上海市','value':'上海市','id':'city1','children':[{ 'text': '7号线', 'value': '7号线', 'id': 'sh7','color': '#ed6f00'},{ 'text': '11号线', 'value': '11号线', 'id': 'sh11' ,'color':'#871c2b'},{ 'text': '10号线', 'value': '10号线', 'id': 'sh10','color':'#c6afd4' },]},{ 'text': '长春市', 'value': '长春市', 'id': 'city2' ,'child':[{ 'text': '3号线', 'value': '3号线', 'id': 'chch3','color': '#ed6f09'},{ 'text': '1号线', 'value': '1号线', 'id': 'chch1' ,'color':'#871c9b'},{ 'text': '8号线', 'value': '8号线', 'id': 'chch8','color':'#c6afd8' },]},{ 'text': '南京市', 'value': '南京市', 'id': 'city3' },]},
                        {'option_title': "线路名称",'key_name':'line_name','value_list': []},
                        {'option_title': "严重等级",'key_name':'grade','value_list': [{ 'text': '可接受', 'value': '可接受', 'id': 'class3','stat':'' },{ 'text': '需重视', 'value': '需重视', 'id': 'class2' },{ 'text': '严重', 'value': '严重', 'id': 'class1' },{ 'text': '非常严重', 'value': '非常严重', 'id': 'class4' },{ 'text': '灾难性', 'value': '灾难性', 'id': 'class5','stat':'disable' },]},
                    **/
            ],
            selected_list: [],
            keyword: "",
        }, 
      selected:this.selectedRow,
    };
  },
  computed: {
    selected_list_text: function() {
      let temp = "";
      for (let i of this.optionData.selected_list) {
        temp += "(" + i.text + ")";
      }
      return temp;
    }
  },
  created() {
    if(this.withOption){
      this.get_option_list();
    }
    this.get_data();
  },
  watch:{
      refreshData:{
        immediate: true,
        handler(){
          this.get_data();
          this.selected = [];
        }
      },
      selected:function(){
        this.$emit('on-selection-change',this.selected);
      }
  },
  methods: {
    click_fu(value) {
      let tmp_key = this.optionData.selected_list.indexOfObject(value);
      if (tmp_key == -1) {
        this.optionData.selected_list.push(value);
      } else {
        this.optionData.selected_list.splice(tmp_key, 1);
      }
    },
    handlePage(value) {
      this.Tpage = value;
      this.get_data();
    },
    get_option_list() {
      this.$axios
        .post(this.$url + this.uri_option_name, {
          userToken: userToken,
          list_name:this.askData,
        })
        .then(response => {
          this.optionData.optionList = response.data.option_list;
      
        });
    },
    get_data() {
      this.tableParams.loading = true;
      this.tableParams.NoDataText = "数据加载中...";
      this.$axios
        .post(this.$url + this.uri_name, {
          page: this.tableParams.Tpage,
          askData:this.askData,
          choosedOptions:{selectedList:this.optionData.selected_list,keyword:this.optionData.keyword},
          userToken: userToken
        })
        .then(
          response => (
            (this.tableParams.contentData = response.data.res_record),
            (this.tableParams.totalItems = response.data.total_n),
            (this.tableParams.loading = false),
            (this.tableParams.NoDataText = "暂无数据")
          )
        );
    },
    minus_fu2(value) {
      let tmp_key = this.optionData.selected_list.indexOfObject(value);
      this.optionData.selected_list.splice(tmp_key, 1);
    },
    reset_choices() {
      this.optionData.selected_list.splice(0, this.optionData.selected_list.length);
      this.go_search();
    },
    handleCurrentChange(value) {
      this.tableParams.Tpage.currentPage = value;
      this.get_data();
    },
    handleSizeChange(value) {
      this.tableParams.Tpage.currentSize = value;
      this.tableParams.Tpage.currentPage = 1;
      this.get_data();
    },
    go_search() {
      this.tableParams.Tpage.currentPage = 1;
      this.get_data();
      this.goAnchor("#data-list");
      this.selected = [];
    },
    goAnchor(selector) {
      this.$el.querySelector(selector).scrollIntoView();
    }
  },  
};
</script>
<style scoped>
.outer-wrapper {
  width: 100%;
  margin: 0 auto;
}

.m-option-wrapper {
  box-shadow: 2px 2px 1px #aaaaaa;
  border: 1px solid #e7e7e7;
  padding: 2px;
  overflow: hidden;
}

.button-wrapper {
  text-align: right;
  height: 50px;
}

.main-button {
  margin-right: 30px;
}

.main-button {
  background-color: #993333;
  color: #ddd;
  border: none;
}

.main-button:hover {
  background-color: #993333;
  border: none;
  color: #fff;
}

.re-button {
  margin-right: 6%;
}

.table-wrapper {
  margin-top: 10px;
  box-shadow: 2px 2px 1px #aaaaaa;
  border: 1px solid #e7e7e7;
  overflow: hidden;
}

.echarts-wrapper {
  margin: 0 auto;
}

.keyword-input-wrapper {
  float: left;
  margin-left: 20px;
}

.keyword-label {
  color: #999;
  font-size: 14px;
}

.keyword-input {
  width: 350px;
  margin-left: 20px;
}

.ins-wrapper {
  font-size: 16px;
  padding: 20px 50px;
  text-align: center;
}

.navImg {
  margin: 0 auto;
  width: 800px;
}

.pane_label {
  font-size: 15px;
  font-weight: 800;
}

.catagory-option-wrapper {
  border: 2px dashed #b4a078;
  margin: 10px 20px;
  padding: 10px 0;
  overflow: hidden;
}

.option-title-wrapper {
  color: #006699;
  font-size: 14px;
  font-family: SimHei;
  text-align: center;
  padding-top: 10px;
  font-weight: 700;
}

.title-box {
  border-left: 1px solid #006699;
  border-right: 1px solid #006699;
  padding: 0 15px;
}

.single-chart-wrapper {
  margin-bottom: 30px;
}
</style>