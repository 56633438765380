<template>
  <query-list
    :columnsFormatData="columnsFormatData"
    :askData="askData"
    :refreshData="update_count"
    uri_name="/auth_interface"
    main_width="1230"
    :withOption="false"
  ></query-list>
</template>
<script>
import queryList from "@/components/Common/QueryList.vue";
//import raListExpand from "./RaListExpand.vue";


export default {
  name: "lineList",
  props: [],
  components: {
    queryList
  },
  data() {
    return {
      op_name: this.$options.name,
      askData: this.$options.name,
      update_count: 0,
      columnsFormatData: [
        /*{
            type: 'expand',
            width: 30,
            render: (h, params) => {
                return h(riskListExpand, {
                    props: {
                        row: params.row
                    }
                })
            }
        },*/
        {
          title: "线路名称",
          key: "line_name",
          align: "center",
          width: 90,
        },
        {
          title: "状态",
          key: "stat",
          align: "center",
          width: 90,
        },
        {
          title: "运营公司",
          key: "operation_company",
          align: "center"
        },
        {
          title: "运营里程(Km)",
          key: "length",
          sortable:true,
          width: 130,
          align: "center"
        },
        {
          title: "车站数量",
          key: "station_amount",
          sortable:true,
          align: "center",
          width: 110
        },
        {
          title: "开通日期",
          key: "open_date",
          sortable:true,
          align: "center",
          width: 110,
        },
        {
          title: "车型",
          key: "train_group",
          width: 100,
          align: "center",
          render: (h, p) => {
            return h(
              "p",
              {
              },
              p.row.carriage_amount+p.row.carriage_style
            );
          }
        },
        {
          title: "最高时速",
          key: "max_speed",
          sortable:true,
          width: 110,
          align: "center",
          render: (h, p) => {
            return h(
              "p",
              {
              },
              p.row.max_speed+'Km/h'
            );
          }
        },
        {
          title: "供电方式",
          key: "power_style",
          width: 90,
          align: "center"
        },
        // {
        //   title: "最大埋深",
        //   key: "max_depth",
        //   width: 90,
        //   align: "center",
        //   render: (h, p) => {
        //     let text;
        //     if(p.row.max_depth!==0){
        //       text = p.row.max_depth+'m';
        //     }else{
        //       text = '-';
        //     }
        //     return h(
        //       "p",
        //       {
        //       },
        //       text
        //     );
        //   }
        // },
        {
          title: "定员载客量",
          key: "specified_capacity",
          width: 105,
          align: "center"
        },
        {
          title: "超员载客量",
          key: "max_capacity",
          width: 105,
          align: "center"
        },
      ]
    };
  },
  methods: {
    goNav() {
      location.href = "/risk_nav";
    },
    linkTo(url) {
      window.open(this.$static_url+url+'?userToken='+localStorage.getItem("current_user_token"));
    }
  }
};
</script>
<style scoped>
</style>